exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boka-js": () => import("./../../../src/pages/boka.js" /* webpackChunkName: "component---src-pages-boka-js" */),
  "component---src-pages-harvard-js": () => import("./../../../src/pages/harvard.js" /* webpackChunkName: "component---src-pages-harvard-js" */),
  "component---src-pages-hudvard-avancerad-js": () => import("./../../../src/pages/hudvard/avancerad.js" /* webpackChunkName: "component---src-pages-hudvard-avancerad-js" */),
  "component---src-pages-hudvard-js": () => import("./../../../src/pages/hudvard.js" /* webpackChunkName: "component---src-pages-hudvard-js" */),
  "component---src-pages-hudvard-vanlig-js": () => import("./../../../src/pages/hudvard/vanlig.js" /* webpackChunkName: "component---src-pages-hudvard-vanlig-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-teamet-js": () => import("./../../../src/pages/teamet.js" /* webpackChunkName: "component---src-pages-teamet-js" */)
}

